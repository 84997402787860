<template>
  <Page>
    <template v-slot:content>
      <Comparator />
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import { Comparator } from "../../organisms";

export default {
  components: {
    Page,
    Comparator,
  },
  metaInfo() {
    return {
      title: this.$t("meta.comparatorPage.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.comparatorPage.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.comparatorPage.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.comparatorPage.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.comparatorPage.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.comparatorPage.image"),
        },
      ],
    };
  },
  mounted() {
    this.$meta().refresh();
  },
};
</script>

<style lang="css" scoped src="./ComparatorPage.css"></style>
